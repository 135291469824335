import React from 'react'
import Footer from './component/Footer/Footer'
import Nav from './component/Nav/Nav'
import Service from './component/Service/Service'
import Product from './component/Product/Product'
import Home from './component/Home/Home'
import './App.css'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

// comment added

const App = () => {
  return (
    <>
     <BrowserRouter>
    <Nav/>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/product" element={<Product/>} />
        <Route exact path="/service" element={ <Service/> } />
      </Routes>
    <Footer/>
    </BrowserRouter>

    </>
  )
}

export default App