import { AppBar, Box, Button, Modal, Toolbar, Typography } from "@mui/material";
import React from "react";
import "./Nav.css";
import { NavLink } from "react-router-dom";
import contactUs from "../../Images/Lets-Get-in-touch.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import pansophicLabs from "../../Images/pansophiclabsFinal_Icon.png"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height:550,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const Nav = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>  
          <nav >
          <div className="logo">
            <img src={pansophicLabs} alt="" className="kainskep_logo" />
          </div>
            <ul className="list">
              <li className="list_items">
                <NavLink to="/"> Home</NavLink>
              </li>
              <li className="list_items services_list">
                <NavLink to="/service"> Services</NavLink>
              </li>
              <li className="list_items">
                <NavLink to="/product">Products</NavLink>
              </li>
              <li>
                <NavLink to="">
                  <Button
                    onClick={handleOpen}
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "#4d6ef2", borderRadius: 0 }}
                    size="large"
                  >
                    Contact Us
                  </Button>
                </NavLink>
              </li>
            </ul>
          </nav>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-header">
            <div className="modal-image">
              <div>Contact Us</div>
              <img alt="contactUs" src={contactUs}></img>
            </div>
            <div className="contact-content">
              <div className="contact-tittle">
                <span>W</span>e provide you bestest solutions{" "}
              </div>
              <div className="contactus-details">
                <div className="email-address">
                  <EmailOutlinedIcon />
                  <div>info@pansophiclabs.com</div>
                </div>
                <div className="mobile-number">
                  <PhoneOutlinedIcon />
                  <div>+91-9509101669</div>
                </div>
                <div className="address">
                  <LocationOnIcon />
                  <div>
                  264, Sachiwalya Nagar, Sitapura, Jaipur, Rajasthan 302022
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default Nav;
