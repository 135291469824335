import React from "react";
import "./Service.css";
import ArtificialIntelligence from "../../Images/ArtificialIntelligence.png";
import BIGdata from "../../Images/BIGdata.png";
import appDevelopment from "../../Images/Mobile-App-Development.jpg.webp";
import appModernization from "../../Images/Grafik-Anbieter-Application-Modernization.webp";
import DevOps from "../../Images/devops_loop-1024x536.jpg";

const Service = () => {
  const services = [
    {
      Image: ArtificialIntelligence,
      tittle: "Artificial Intelligence",
      content:
        "We assist you in making of a relevant Business case. Our customised AI solutions that will turn",
    },
    {
      Image: BIGdata,
      tittle: "Big Data & Analytics",
      content:
        "Formulate an Enterprise wide data strategy and estate with us. ",
    },
    {
      Image: appDevelopment,
      tittle: "Application Development ",
      content:
        "We assist you in making of a relevant Business case. Our customised AI solutions that will turn",
    },
    {
      Image: appModernization,
      tittle: "App Modernization",
      content:
        "We assist you in making of a relevant Business case. Our customised AI solutions that will turn",
    },
    {
      Image: DevOps,
      tittle: "DevOps & Automation",
      content:
        "We assist you in making of a relevant Business case. Our customised AI solutions that will turn",
    },
  ];
  return (
    <>
      <div className="Services">
        <div className="services-heading">Services</div>
        <div className="services-wrapper">
          {services.map((val, Idx) => {
            return (
              <div className="service-card" key={Idx}>
                <div className="service-card-image">
                  <img alt="servies-images" src={val.Image}></img>
                </div>
                <div className="service-card-title">{val.tittle}</div>
                <div className="service-card-content">{val.content}</div>
              </div>
            );
          })}
        </div>
        <div className="services-pansophic-continer">
          <div className="services-pansophic-title">
            Excel your business with Pansophic
          </div>
          <p className="services-pansophic-subtitle">
            We build solutions for the future to make your business easier.
          </p>
        </div>
      </div>
    </>
  );
};

export default Service;
