import React from "react";
import "./Product.css";
import productBanner from "../../Images/product-banner.jpeg";
import SAPAzure from "../../Images/SAP-Azure.png";
import factoryVision from "../../Images/factory-vision.png";
import financialSolution from "../../Images/financial-solution.png";
import comingSOON from "../../Images/comingSOON.jpeg";
const Product = () => {
  const product = [
    {
      Image: SAPAzure,
      tittle: "SAP Analytics on Azure",
      content:
        "We offers pre-packaged SAP Planning, Analytics, and Reporting packages on Azure to help your company see quick time to value with your SAP investment. We offer both quick start programs which deploy in weeks and small-to-medium pre-packaged managed implementations.",
    },
    {
      Image: factoryVision,
      tittle: "Vision for Factories",
      content:
        "Our AI engine Eleven is a computer vision engine, which helps organizations to ensure the workplace safety inside the factories. It scans the workers and identify safety violation. Along with that it provides out-of-the-box reporting feature to monitor the violations in real-time.",
    },
    {
      Image: financialSolution,
      tittle: "Financial Solutions ",
      content:
        "MTX works side by side with leading financial institutions to create simplified solutions for common transactions, saving time and money while improving the customer experience. An in-depth and configurable process to make it easy for your customers to invest their money",
    },
    {
      Image: comingSOON,
      tittle: "UpComing",
      // content:
      //   "We assist you in making of a relevant Business case. Our customized AI solutions that will turn",
    },
  ];
  return (
    <>
      <div className="product">
        <div className="product-header">
          <div className="product-card">
            <div className="product-card-tittle">Our Products</div>
            <div className="product-card-content">
              Employee engagement is the emotional connection which an employee
              feels towards their organization, that influences their level of
              effort in work-related activities. The main purpose of employee
              engagement is to create a work environment where employees love
              and care about their work.
            </div>
          </div>
          <div className="product-banner">
            <img alt="banner-image" src={productBanner}></img>
          </div>
        </div>
        <div>
          <div className="about-product">
            <p>
              <span>D</span>isengaged employees can hurt your business, big time. They may be
              costing your company more money than you think. We built Empcare
              because we know that when you appreciate and take care of your
              employees, they'll take care of your business.
            </p>
          </div>
        </div>
        <div className="products-section">
          <div className="product-title">Products</div>
          <div className="products-wrapper">
            {product.map((val, Idx) => {
              return (
                <div className="products-card" key={Idx}>
                  <div className="products-card-image">
                    <img alt="products-images" src={val.Image}></img>
                  </div>
                  <div className="products-card-title">{val.tittle}</div>
                  <div className="products-card-content">{val.content}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="products-pansophic-continer">
          <div className="products-pansophic-title">
            Excel your business with Pansophic
          </div>
          <p className="products-pansophic-subtitle">
            We build solutions for the future to make your business easier.
          </p>
        </div>
      </div>
    </>
  );
};

export default Product;
