import React from "react";
// import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { Button, Grid } from '@mui/material';
import "./Home.css";
import bannerImage from "../../Images/flat-web-development-concept-cms_23-2148806100.webp"
import ArtificialIntelligence  from "../../Images/ArtificialIntelligence.png"
import BIGdata  from "../../Images/BIGdata.png"
import cloud  from "../../Images/cloud.png"
import BusinessTeamWorking  from "../../Images/Ellipse 2.png"
const Home = () => {
  
  const Services =[
    {
      Image:ArtificialIntelligence,
      tittle:" Artificial Intelligence",
      content:"We assist you in making of a relevant Business case. Our customised AI solutions that will turn"
    },
    {
      Image:BIGdata,
      tittle:" Big Data & Analytics",
      content:"Formulate an Enterprise wide data strategy and estate with us. "
    },
    {
      Image:cloud,
      tittle:" Cloud Revolution",
      content:"We help you in migrating to Enterprise cloud. Our accelerators "
    },
    
   ]
  return (
    <>
     <div className="image_section">
        <div className="tittle-box">
          <div className='head-title'><span>Data </span>converters</div>
          <p>AdWords Conversion Optimizer is a tool that helps acquire the most 
          conversions possible at a automatically adjusts bids so conversions are 
          generated within a defined CPA boundary.
          </p>
        </div>
        <div className='image-container'>
           <img src={bannerImage}></img>
        </div>
       </div>
       <div className="consulting_section">
      <Container>
        <Grid className="pansophic-continer">
          <div className="pansophic-title">Excel your business with Pansophic</div>
          <p className="pansophic-subtitle">We build solutions for the future to make your business easier.</p>
        </Grid>
      </Container>
    </div>
    <div className="what_we_do_section">
    <div className='services-tittle'>Our Services</div>
    <div className='services-container'>
    {
        Services.map((Item,Idx)=>{
          return (
            <div className='card' key={Idx}>
                   <div className='card-image'>
                    <img src={Item.Image}></img>
                   </div>
                   <div className='card-title'>
                    {Item.tittle}
                   </div >
                   <div className='card-content'>{Item.content}</div>
            </div>
          )
        })
      }
    </div>
   </div>
   <div className='consulting-container'>
           <div className='consulting-image-container'>
            <img src={BusinessTeamWorking}></img>
           </div>
           <div className='consulting-title'>
           Why our Consulting?
           </div>
           <div className='consulting-content'>
           We aim to have happy, satisfied customers and we strongly believe: “Leave your technology to us while you keep doing what you do best - running your business!”
           </div>
           <div className='consulting-button'>
            <Button>Our Products</Button>
           </div>
   </div>

    </>
  );
};

export default Home;
