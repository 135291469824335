import React from "react";
import "./Footer.css";
import footerLogo from "../../Images/pansophicLabsFooter_Logo.jpg";

const Footer = () =>{
  return (
    <>
      <footer>
          <div>
            <div className="ft-logo">
              <img src={footerLogo} />
            </div>
            <ul>
              <li>info@pansophiclabs.com</li>
              <li>+91-9509101669</li>
              <li>
                {" "}
                264, Sachiwalya Nagar, Sitapura, Jaipur, Rajasthan 302022
              </li>
            </ul>
          </div>
      </footer>
      <div className="text-center footer_under">© 2022 Pansophic labs</div>
    </>
  );
};

export default Footer;
